import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query Newsletters {
        newsletters: allFile(
            filter: {relativeDirectory: {eq: "downloads/newsletter"}},
            sort: {fields: name, order: DESC},
            limit: 12
        ) {
            edges {
                node {
                    name
                    publicURL
                    ext
                    size
                }
            }
        }
    }
`


class Newsletters extends React.Component {
    render() {
        const newsletters = this.props.data.newsletters.edges;

        return (
            <Layout
                title="Newsletters"
                location={this.props.location}
            >
                <p>The DBHS Newsletter gives up-to-date news of the Society, any gardening events in the area, and is also intended as a collection of articles contributed by members. The Newsletter comes out monthly and is distributed to all Society members. Please see the following links for a sample of previous newsletters:</p>

                {newsletters.map(({ node }) => {
                    return (
                        <li key={node.name}>
                            <Link to={node.publicURL}>
                                {node.name}{node.ext}
                            </Link>
                            {' '}[{(node.size / 1024).toFixed(2)} kb]
                        </li>
                    )
                })}
            </Layout>
        )
    }
}
        
export default Newsletters